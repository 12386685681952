import { Button, Card, Col, Form, Input, Row } from "antd";
import { passwordReset } from "api/auth.api";
import { PageTitle } from "components/Common/HeadingAndCommonDetails/Label";
import { notificationController } from "components/Common/Notification/Notification";
import { BlackLogo } from "Icon/Icon";
import React, { useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { validatePassword } from "utils/Passwordvalidators";

const ResetPasswordMain = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleSubmit = async (values: any) => {
    if (token) {
      setLoading(true);
      try {
        const res = await passwordReset({
          token: token,
          password: values.password,
          confirmPassword: values.confirmPassword,
        });
        notificationController.success({
          message: res.message || "Password reset successfully!",
        });

        // Reset form fields
        form.resetFields();

        // Redirect to login page after a short delay
        setTimeout(() => {
          navigate("/auth/login");
        }, 1000);
      } catch (err: any) {
        notificationController.error({
          message: err?.message || "Failed to reset password.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return token ? (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card bordered={true} style={{ width: 400 }} className="shadow">
        <Row justify={"center"}>
          <Col className="mb-6">
            <BlackLogo />
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col className="mb-3">
            <PageTitle text="Reset Password" />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              form={form}
              name="resetPassword"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const error = validatePassword(value);
                      return error ? Promise.reject(new Error(error)) : Promise.resolve();
                    },
                  },
                ]}
                style={{ width: "48%" }}
              >
                <Input.Password size="large" placeholder="Enter Password" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords that you entered do not match!")
                      );
                    },
                  }),
                ]}
                style={{ width: "48%" }}
              >
                <Input.Password size="large" placeholder="Confirm Password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Reset Now
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  LogIn
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default ResetPasswordMain;
