/* eslint-disable max-len */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/reduxHooks";
import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { doLogin } from "store/slices/auth.slice";
import { notificationController } from "components/Common/Notification/Notification";
import { LoginRequest } from "api/auth.api";
import { VerifymeLogo } from "Icon/Icon";
import { PageTitle } from "components/Common/HeadingAndCommonDetails/Label";

const { Text } = Typography;

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: LoginRequest) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Card bordered className="shadow rounded-3xl" style={{ width: 400 }}>
        {/* Logo Section */}
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Col>
            <VerifymeLogo />
          </Col>
        </Row>

        {/* Title and Subtitle */}
        <Row justify="center" style={{ marginBottom: 16 }}>
          <Col>
            <PageTitle text="Let the Journey Begin!" />
            <Text className="text-[#0E0E0E]" style={{ display: "block", marginBottom: 10 }}>
              Welcome back! Please enter your details.
            </Text>
          </Col>
        </Row>

        {/* Login Form */}
        <Form
          layout="vertical"
          name="loginForm"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          {/* Username Input */}
          <Form.Item
            label="Username"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input size="large" placeholder="Enter Email" />
          </Form.Item>

          {/* Password Input */}
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password size="large" placeholder="Enter Password" />
          </Form.Item>

          {/* Forgot Password Link */}
          <Form.Item style={{ marginBottom: 8 }}>
            <Row justify="end">
              <Link to="/auth/forgot-password" className="text-black-body hover:text-primary-color">
                Forgot Password?
              </Link>
            </Row>
          </Form.Item>

          {/* Submit Button */}
          <Form.Item style={{ marginBottom: 8 }}>
            <Button type="primary" htmlType="submit" className="w-full" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>

          {/* Register Link */}
          <Row justify="center" gutter={0}>
            <Text>
              Don&apos;t have an account?
              <Button
                type="link"
                onClick={() => navigate("/auth/register")}
                className="text-primary-color"
                style={{ padding: "4px" }}
              >
                Register
              </Button>
            </Text>
          </Row>
        </Form>
      </Card>
    </Row>
  );
};
