import { Button, Card, Col, Form, Input, Row } from "antd";
import { forgotPassword } from "api/auth.api";
import { PageTitle } from "components/Common/HeadingAndCommonDetails/Label";
import { notificationController } from "components/Common/Notification/Notification";
import { BlackLogo } from "Icon/Icon";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPasswordMain = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm(); // Create form instance

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const res = await forgotPassword(values.email);
      notificationController.success({
        message: res.message || "Password reset email sent successfully!",
      });

      form.resetFields();
    } catch (err: any) {
      notificationController.error({
        message: err?.message || "Failed to send password reset email.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card bordered={true} style={{ width: 400 }} className="shadow">
          <Row justify={"center"}>
            <Col className="mb-6">
              <BlackLogo />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col className="mb-3">
              <PageTitle text="Forgot Password" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
                form={form}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Email" />
                </Form.Item>

                <Form.Item>
                  <Button className="w-full" type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                  </Button>
                  <Button
                    className="text-primary-color"
                    type="link"
                    onClick={() => {
                      navigate("/auth/login");
                    }}
                  >
                    {" "}
                    Back to Login Page
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default ForgotPasswordMain;
