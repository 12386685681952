import ForgotPasswordMain from "components/ForgotPassword/ForgotPasswordMain";
import AuthLayout from "components/Login/AuthLayout";
import React from "react";

const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <ForgotPasswordMain />
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
