export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const validatePassword = (value: string): string | undefined => {
  if (!value) {
    return "";
  }
  if (!passwordRegex.test(value)) {
    return "Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character.";
  }
  return undefined; // Valid password
};
