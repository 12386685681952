import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

// Define allowed status values for Ant Design's Result component
type ResultStatusType = "404" | "403" | "500" | "error" | "success" | "info" | "warning";

interface NotFoundPageProps {
    status?: number;
    message?: string;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ status = 404, message = "Sorry, the page you visited does not exist." }) => {
  const navigate = useNavigate();

  // Map numeric status codes to Ant Design's allowed status values
  const statusMap: Record<number, ResultStatusType> = {
    404: "404",
    403: "403",
    500: "500",
  };
  const resultStatus = statusMap[status] || "error";

  return (
    <Result
      status={resultStatus}
      title={status.toString()}
      subTitle={message}
      extra={
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Button style={{ textAlign: "center" }} type="primary" onClick={() => navigate("/")}>
                        Back Home
          </Button>
        </div>
      }
    />
  );
};

export default NotFoundPage;
