import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input, Select, Row, Col, Flex } from "antd";
import { CreateTeamMember, getTeamMember, UpdateTeamMember, UserFormValues } from "api/teams.api";
import { notificationController } from "components/Common/Notification/Notification";
// import { ArrowLeftOutlined } from "@ant-design/icons";
import VerificationLayout from "layouts/VerificationLayout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { validatePassword } from "utils/Passwordvalidators";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const AddTeamMemberMain: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [teamsMember, setTeamsMember] = useState<UserFormValues | null>(null);

  const memberId = searchParams.get("memberId");
  const role = "TeamMember";
  // const hardcodedParentId = 1;

  const handleSubmit = (values: UserFormValues) => {
    setIsLoading(true);

    const updatedValues = { ...values, role };

    if (memberId) {
      UpdateTeamMember(updatedValues, parseInt(memberId))
        .then(() => {
          notificationController.success({ message: "Team Member Updated!" });
          setIsLoading(false);
          navigate("/teams");
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
          setIsLoading(false);
        });
    } else {
      CreateTeamMember(updatedValues)
        .then(() => {
          notificationController.success({
            message:
              "Team member added successfully! A verification request has been sent to the team member.",
          });
          setIsLoading(false);
          navigate("/teams");
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (memberId) {
      getTeamMember(memberId)
        .then((response: UserFormValues) => {
          setTeamsMember(response);
          form.setFieldsValue(response);
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
        });
    }
  }, [memberId, form]);

  return (
    <VerificationLayout
      prefix={<Button type="text" onClick={() => navigate(-1)} icon={<ArrowLeftOutlined />} />}
      heading={memberId ? "Edit Team Member" : "Add Team Member"}
    >
      <div className="panlist-dash">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          initialValues={teamsMember ?? {}}
          className=" m-auto w-10/12"
        >
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                <Select size="large" placeholder="Select a title">
                  <Select.Option value="Mr.">Mr.</Select.Option>
                  <Select.Option value="Mrs.">Mrs.</Select.Option>
                  <Select.Option value="Miss">Miss</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
                <Input size="large" placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email address" },
                ]}
              >
                <Input size="large" placeholder="Enter Your Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="mobile"
                label="Mobile Number"
                rules={[
                  { required: true, message: "Please enter your mobile number" },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Mobile number must be 10 digits",
                  },
                ]}
              >
                <Input maxLength={10} size="large" placeholder="Enter Your Mobile Number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, value) => {
                      const error = validatePassword(value);
                      return error ? Promise.reject(new Error(error)) : Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password size="large" placeholder="Enter Password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords that you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Confirm Password" />
              </Form.Item>
            </Col>
          </Row>
          <Flex className="mb-0 flex gap-2 items-center">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {memberId ? "Update" : "Submit"}
            </Button>
            <Button onClick={() => navigate("/teams")} className="capitalize" loading={isLoading}>
              Team List
            </Button>
          </Flex>
        </Form>
      </div>
    </VerificationLayout>
  );
};

export default AddTeamMemberMain;
