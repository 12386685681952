export const APIEndPoints = {
  PAN: { name: "PAN", basePrice: 100 },
  PAN_ALL_IN_ONE: { name: "PAN All In One", basePrice:  100},
  DL: { name: "Driving License", basePrice: 100},
  GST: { name: "GST", basePrice: 100},
  VOTER: { name: "Voter", basePrice:  100},
  AADHAR_BASIC: { name: "Aadhaar Basic", basePrice:  100 },
  AADHAR_INITIAL: { name: "Aadhaar Initial", basePrice:  100 },
  RC: { name: "RC Number", basePrice: 100 },
  CREDIT_SCORE: { name: "Credit Score", basePrice: 3000},
  UDYAM: { name: "Udyam", basePrice:  100 },
  PASSPORT: { name: "Passport", basePrice:  100},
  COMPANYDETAILS: { name: "Company Details", basePrice:  100 },
  // GEN_AADHAR_OTP: { name: "Aadhaar OTP", basePrice:  100 },
  MOBILE_TO_EPFO: { name: "Employment Verification", basePrice: 100},
  MOBILE_TO_UDYAM_NUMBER: { name: "Mobile to Udyam", basePrice:  100},
  MOBILE_TO_UAN: { name: "Mobile to UAN", basePrice:  100 },
  PAN_TO_UDYAM: { name: "PAN to Udyam", basePrice:  100 },
  CRIME: { basePrice: 5000},
};
