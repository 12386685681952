import authReducer from "store/slices/auth.slice";
import useReducer from "store/slices/user.slice";
import backgroundCheck from "store/slices/backgroundCheck.slice";
import routesManage from "store/slices/routesManage.slice";
const reducer ={
  auth: authReducer,
  user: useReducer,
  backgroundCheck: backgroundCheck,
  routesManage: routesManage,
 
};

export default reducer;
