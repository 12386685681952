import React, { useEffect } from "react";
import { Layout } from "antd";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../components/Common/Headers/Navbar";
import CommonPageHeader from "components/Common/Headers/CommonPageHeader";
import { getCreditInfo } from "store/slices/auth.slice";
import { useAppDispatch } from "hooks/reduxHooks";
import StickyBox from "react-sticky-box";
import Footer from "components/Common/Footer/AppFooter";

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
  textAlign: "center",
  padding: "30px 15px",
  color: "#fff",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
};

const layoutStyle: React.CSSProperties = {
  borderRadius: 8,
  overflow: "hidden",
  width: "100%",
  height: "100vh",
  display: "flex", // Add flexbox
};

const contentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column", // Column layout
  height: "100%",
};

const mainContentStyle: React.CSSProperties = {
  flex: 1, // Allow the content to grow and push the footer down
  overflowY: "scroll",
  padding: "10px 20px",
};

// const footerStyle: React.CSSProperties = {
//   background: "#0E0E0E",
//   color: "#fff",
//   textAlign: "center",
//   padding: "10px 0",
// };

const NewLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCreditInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout style={layoutStyle} className="bg-primary-bg p-2 relative overflow-hidden">
      <Sider
        theme="dark"
        width="290px"
        style={siderStyle}
        className="main-sidebar overflow-y-auto overflow-x-hidden bg-[#0E0E0E]"
      >
        <Link to="/" style={{ display: "flex", justifyContent: "center" }} className="mb-8">
          <img
            src="Logo-white.svg"
            alt=""
            style={{ maxWidth: "10rem", height: "auto", margin: "auto 0" }}
          />
        </Link>
        <Navbar />
      </Sider>
      <Layout style={contentStyle} className="bg-primary-bg">
        <StickyBox offsetTop={0} className="z-10 bg-primary-bg p-5 pb-0">
          <CommonPageHeader />
        </StickyBox>
        <div style={mainContentStyle}>
          <Outlet />
        </div>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default NewLayout;
