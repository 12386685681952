import React, { Suspense } from "react";
// import { Loading } from "components/Common/Loading/Loading";
import { Spin } from "antd";
// eslint-disable-next-line no-unused-vars
type ReturnType<T> = (props: T) => JSX.Element;

export const withLoading = <T extends object>(Component: React.ComponentType<T>): ReturnType<T> => {
  return (props: T) => (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
