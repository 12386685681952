import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoutesManage {
  selectedHeading: string;
}

const initialState: RoutesManage = {
  selectedHeading: "PAN",
};

const routesManage = createSlice({
  name: "routesManage",
  initialState,
  reducers: {
    setSelectedHeading(state, action: PayloadAction<string>) {
      state.selectedHeading = action.payload;
    },
  },
});

export const { setSelectedHeading } = routesManage.actions;

export default routesManage.reducer;
