import React from "react";

import { Login } from "components/Login/Login";
import AuthLayout from "components/Login/AuthLayout";

const LoginPage: React.FC = () => {
  return (
    <>
      <AuthLayout>
        <Login></Login>
      </AuthLayout>
    </>
  );
};

export default LoginPage;
