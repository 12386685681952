/* eslint-disable max-len */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Col, Form, Input, Row, Flex } from "antd";
import { notificationController } from "components/Common/Notification/Notification";

import { SignUpRequest, signUp } from "api/auth.api";
import { validatePassword } from "utils/Passwordvalidators";
import AuthLayout from "components/Login/AuthLayout";
import { BlackLogo } from "Icon/Icon";
import { PageTitle } from "components/Common/HeadingAndCommonDetails/Label";

export const Register: React.FC = () => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: SignUpRequest) => {
    setLoading(true);
    signUp({ ...values, title: "Mr", acceptTerms: true }).then(
      (data) => {
        notificationController.success({ message: data.message });
        setLoading(false);
        navigate("/auth/login");
      },
      (error) => {
        setLoading(false);
        notificationController.success({ message: error.message });
      }
    );
  };

  return (
    <AuthLayout isReverse={true}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card bordered={true} className="shadow" style={{ maxWidth: "500px", width: "100%" }}>
          {" "}
          {/* Fix card width */}
          <Row justify={"center"}>
            <Col className="mb-6">
              <BlackLogo />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col className="mb-3">
              <PageTitle text="Register" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
                className="flex flex-col"
              >
                <Flex gap={8}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input size="large" placeholder="Enter First Name" />
                  </Form.Item>

                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input size="large" placeholder="Enter Last Name" />
                  </Form.Item>
                </Flex>
                <Flex gap={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input size="large" placeholder="Enter Your Email" />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please input your mobile number!",
                      },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input maxLength={10} size="large" placeholder="Enter Mobile Number" />
                  </Form.Item>
                </Flex>
                <Flex gap={8}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        validator: (_, value) => {
                          const error = validatePassword(value);
                          return error ? Promise.reject(new Error(error)) : Promise.resolve();
                        },
                      },
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input.Password size="large" placeholder="Enter Password" />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The two passwords that you entered do not match!")
                          );
                        },
                      }),
                    ]}
                    style={{ width: "48%" }}
                  >
                    <Input.Password size="large" placeholder="Confirm Password" />
                  </Form.Item>
                </Flex>
                <Form.Item>
                  <Button className="w-full" type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                  </Button>
                  <Flex gap={8}>
                    <span className="text-center w-full">
                      Already a member?{" "}
                      <Button
                        type="link"
                        onClick={() => {
                          navigate("/auth/login");
                        }}
                        className="p-0 text-primary-color"
                      >
                        Please login to your account.
                      </Button>
                    </span>
                  </Flex>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </AuthLayout>
  );
};
