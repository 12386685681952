import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectionState {
  selectedLabel: string;
  selectedBusinessLabel: string;
}

const initialState: SelectionState = {
  selectedLabel: "PAN", // Default value
  selectedBusinessLabel: "GST Verification", // Default value
};

const backgroundCheck = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelectedLabel(state, action: PayloadAction<string>) {
      state.selectedLabel = action.payload;
    },
    setSelectedBusinessLabel(state, action: PayloadAction<string>) {
      state.selectedBusinessLabel = action.payload;
    },
  },
});

export const { setSelectedLabel, setSelectedBusinessLabel } = backgroundCheck.actions;

export default backgroundCheck.reducer;
